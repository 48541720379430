<template>
  <div class="lsg-calc">
    <div class="main__header">
      <div class="main__header__item left"></div>
      <div class="main__header__item">
        <h1>Калькулятор</h1>
      </div>
      <div class="main__header__item right"></div>
    </div>
    <div class="main__content">
      <div class="calc" v-bind:class="{ 'calc--center': this.$store.state.me.role === 'partner' }">
        <div v-if="this.$store.state.me.role !== 'partner'" class="calc__form" v-bind:style="{marginTop: marginTopCalc + 'px'}">
          <div class="_row calc-types" style="overflow-x: hidden; flex-wrap: nowrap">
            <label class="_col-4 calc__form__type" :class="{active: activeItem.light}">
              <input type="radio" value="light" name="propType" @click="setType">
              <i>
                <CarIcon/>
              </i>
              <span>Легковой транспорт</span>
            </label>
            <label class="_col-4 calc__form__type" :class="{active: activeItem.commercial}">
              <input type="radio" value="commercial" name="propType" @click="setType">
              <i>
                <TruckIcon/>
              </i>
              <span>Коммерческий транспорт</span>
            </label>
            <label class="_col-4 calc__form__type" :class="{active: activeItem.special}">
              <input type="radio" value="special" name="propType" @click="setType">
              <i>
                <TractorIcon/>
              </i>
              <span>Спецтехника</span>
            </label>
            <label class="_col-4 calc__form__type" :class="{active: activeItem.equipment}">
              <input type="radio" value="equipment" name="propType" @click="setType">
              <i>
                <FactoryIcon/>
              </i>
              <span>Оборудование</span>
            </label>
            <label class="_col-4 calc__form__type" :class="{active: activeItem.realEstate}">
              <input type="radio" value="realEstate" name="propType" @click="setType">
              <i>
                <RealEstateIcon/>
              </i>
              <span>Недвижимость</span>
            </label>
            <label class="_col-4 calc__form__type" :class="{active: activeItem.residentialEstate}">
              <input type="radio" value="residentialEstate" name="propType" @click="setType">
              <i>
                <RealEstateIcon/>
              </i>
              <span>Жилая недвижимость</span>
            </label>
          </div>
          <div class="_row">
            <div class="calc__form__item _col-2">
              <label>Наименование</label>
              <input type="text" v-model="createFields.name">
            </div>
            <div class="calc__form__item _col-2">
              <label>Стоимость</label>
              <input
                min="0"
                v-model="modelPrice"
                :type="indicators.price ? 'number' : 'text'"
                @focus="indicators.price = true"
                @blur="indicators.price = false"
              />
            </div>
            <div class="calc__form__item _col-2">
              <label>Аванс</label>
              <input
                min="0"
                v-model="modelPrepaid"
                :type="indicators.prepaid ? 'number' : 'text'"
                @focus="indicators.prepaid = true"
                @blur="indicators.prepaid = false"
              />
            </div>
            <div class="calc__form__item _col-2">
              <label>Аванс(%)</label>
              <input type="number" v-model.number="prepaidProc" min="0" max="50">
            </div>
            <div v-if="activeItem.realEstate" class="calc__form__item _col-2">
              <label>НДС(%)</label>
              <select v-model="createFields.nds" class="dark-select">
                <option value="НДС20" selected>20</option>
                <option value='БезНДС'>Без НДС</option>
              </select>
            </div>
            <div v-if="activeItem.realEstate || activeItem.residentialEstate" class="calc__form__item _col-2">
              <label>Кадастровая стоимость</label>
              <input type="number" v-model.number="createFields.cadastral">
            </div>
            <div class="calc__form__item _col-2">
              <label>Срок лизинга</label>
              <input type="number" v-model.number="createFields.duration" min="12" max="60">
            </div>
            <div class="calc__form__item _col-2">
              <label>Вид графика</label>
              <select v-model="createFields.graphType" class="dark-select">
                <option
                  v-for="(item, mIndex) in graphTypeSelect"
                  :key="mIndex"
                  :value="item.value"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="calc__form__item _col-2"
              v-if="createFields.graphType === 'annuityStair'||
                    createFields.graphType === 'drawdown'"
            >
              <label>Сила наклона</label>
              <input type="number" min="0" max="300" step="1" v-model.number="createFields.strength">
            </div>
            <div class="calc__form__item _col-2"
              v-if="createFields.graphType === 'annuityStair'"
            >
              <label>Месяцев в ступени</label>
              <select v-model="createFields.stair" class="dark-select">
                <option
                  v-for="(item, mIndex) in graphStairSelect"
                  :key="mIndex"
                  :value="item.value"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="calc__form__additional">
            <div class="calc__form__additional__header" @click="additional = !additional">
              <i>
                <DownIcon/>
              </i>
              <span>Дополнительные настройки</span>
            </div>
            <div class="_row" v-show="additional">
              <div class="calc__form__item _col-3">
                <label>Ставка привлечения</label>
                <input type="number" min="0" v-model.number="createFields.rate">
              </div>
              <div class="calc__form__item _col-3">
                <label>Комиссия менеджера</label>
                <input type="number" min="0" max="1.2" step="0.1" v-model.number="createFields.managerCom">
              </div>
              <div class="calc__form__item _col-3">
                <label>Комиссия агента</label>
                <input type="number" min="0" max="2" step="0.1" v-model.number="createFields.agentCom">
              </div>
            </div>
          </div>
        </div>
        <div v-if="this.$store.state.me.role === 'partner'" class="calc__form calc__form--client" v-bind:style="{marginTop: marginTopCalc + 'px'}">
          <div class="_row calc-types--client">
            <label class="_col-4 calc__form__type" :class="{active: activeItem.light}">
              <input type="radio" value="light" name="propType" @click="setType">
              <i>
                <CarIcon/>
              </i>
              <span>Легковой транспорт</span>
            </label>
            <label class="_col-4 calc__form__type" :class="{active: activeItem.commercial}">
              <input type="radio" value="commercial" name="propType" @click="setType">
              <i>
                <TruckIcon/>
              </i>
              <span>Коммерческий транспорт</span>
            </label>
            <label class="_col-4 calc__form__type" :class="{active: activeItem.special}">
              <input type="radio" value="special" name="propType" @click="setType">
              <i>
                <TractorIcon/>
              </i>
              <span>Спецтехника</span>
            </label>
            <label class="_col-4 calc__form__type" :class="{active: activeItem.equipment}">
              <input type="radio" value="equipment" name="propType" @click="setType">
              <i>
                <FactoryIcon/>
              </i>
              <span>Оборудование</span>
            </label>
            <label class="_col-4 calc__form__type" :class="{active: activeItem.realEstate}">
              <input type="radio" value="realEstate" name="propType" @click="setType">
              <i>
                <RealEstateIcon/>
              </i>
              <span>Недвижимость</span>
            </label>
          </div>
          <div class="_row">
            <div class="calc__form__item _col-2">
              <label>Стоимость предмета лизинга</label>
              <input
                min="0"
                v-model="modelPrice"
                :type="indicators.price ? 'number' : 'text'"
                @focus="indicators.price = true"
                @blur="indicators.price = false"
              />
            </div>
            <div class="calc__form__item _col-2">
              <label>Первоначальный взнос, (до {{ prettifyPrice(Number(modelPrice.replace(/\s/g, '')) * 0.499) }} руб.)</label>
              <input
                min="0"
                v-model="modelPrepaid"
                :type="indicators.prepaid ? 'number' : 'text'"
                @focus="indicators.prepaid = true"
                @blur="indicators.prepaid = false"
              />
            </div>
            <div class="calc__form__item _col-1">
              <label>Срок лизинга (от 12 до 60 мес)</label>
              <input type="number" v-model.number="createFields.duration" min="12" max="60">
            </div>
          </div>
          <div class="calc__form__note _row">
            <span>
              Расчет является примерным и не включает страховые взносы. Представленные сведения носят исключительно информационный характер и ни при каких условиях не
              являются публичной офертой. Окончательные условия каждого договора определяются в индивидуальном порядке. Для получения конечной цены вы можете заполнить форму заявки.
            </span>
          </div>
        </div>
        <div v-if="this.$store.state.me.role === 'partner'" class="calc__results">
          <div class="calc__results__white">
            <span class="calc__results__title">Результаты расчёта:</span>
            <span class="calc__results__price">{{ prettifyPrice(this.payment) }} ₽</span>
            <span class="calc__results__type">Ежемесячный платеж</span>
          </div>
          <div class="calc__results__dark">
            <div class="calc__results__item">
              <div class="calc__dark__price">{{ this.sum }} ₽</div>
              <div class="calc__dark__type">Сумма договора</div>
            </div>
            <div class="calc__results__item">
              <div class="calc__dark__price">{{ prettifyPrice( this.economy) }} ₽</div>
              <div class="calc__dark__type">Экономия на налогах</div>
            </div>
          </div>
          <div class="calc__results__btn">
            <router-link :to="{ name: 'apply' }" class="btn">Отправить заявку</router-link>
          </div>
          <span class="calc__results__note">
            Сезонным бизнесам — сезонные графики. Подробности у менеджера.
          </span>
        </div>
        <ApolloQuery
          v-if="input"
          :query="require('@/graphql/queries/TryCalc.gql')"
          :variables="{ input }"
          @error="handleErrors"
          class="calc__info"
          v-bind:class="{ 'calc__info--center': this.$store.state.me.role === 'partner' }"
        >
          <template slot-scope="{ result: { loading, error, data } }">
            <div v-if="error" class="messages apollo error">
              <div class="messages__status error">{{ error.message }}</div>
            </div>
            <template v-if="!loading && !error">
              <div class="calc__info__header" v-if="data">
                <div v-if="$store.state.me.role !== 'partner'" class="_row">
                  <div class="calc__info__item calc__info__item--orange _col-3">
                    <span class="calc__info__item__title">Сумма договора</span>
                    <span class="calc__info__item__subtitle">{{ prettifyPrice(data.tryCalc.summ) }} руб.</span>
                  </div>
                  <div class="calc__info__item _col-3">
                    <span class="calc__info__item__title">Минимальная ставка</span>
                    <span class="calc__info__item__subtitle">{{ prettifyPrice(data.tryCalc.minRate) }} %</span>
                  </div>
                  <div class="calc__info__item _col-3">
                    <span class="calc__info__item__title">Максимальная комиссия</span>
                    <span class="calc__info__item__subtitle">
                      <span>{{ prettifyPrice(data.tryCalc.maxAgent) }} %</span>
                    </span>
                  </div>
                </div>
                <div v-if="$store.state.me.role !== 'partner'" class="_row">
                  <div class="calc__info__download _col-1">
                    <a href="#" class="btn" @click="downloadCalc('d669589a-71ad-11ea-80bf-00259047d1e4')">Скачать оферту.pdf</a>
                  </div>
                </div>
                <div class="_row">
                <div v-bind:style="{ display: 'none' }">{{ readSum(prettifyPrice(data.tryCalc.summ)) }}</div>
                <div :class="{ '_col-2': data.tryCalc.graph.length > 14, '_col-1': data.tryCalc.graph.length <= 14}">
                  <vue-good-table max-height="60vh" v-if="data.tryCalc.graph.length <= 14" :columns="columns" :pagination-options="{enabled: false}" :rows="readPayment(data.tryCalc.graph)" compactMode>
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field === 'number'">{{ props.row.lineNo }}</span>
                      <span v-if="props.column.field === 'plan'">{{ prettifyPrice(props.row.plan) }} руб.</span>
                    </template>
                    <div slot="emptystate">Данных нет</div>
                  </vue-good-table>
                  <vue-good-table max-height="60vh" v-else :columns="columns" :pagination-options="{enabled: false}" :rows="readPayment(data.tryCalc.graph.slice(0, data.tryCalc.graph.length / 2))" compactMode>
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field === 'number'">{{ props.row.lineNo }}</span>
                      <span v-if="props.column.field === 'plan'">{{ prettifyPrice(props.row.plan) }} руб.</span>
                    </template>
                    <div slot="emptystate">Данных нет</div>
                  </vue-good-table>
                </div>
                <div class="_col-2" v-if="data.tryCalc.graph.length > 14">
                  <vue-good-table max-height="60vh" :columns="columns" :pagination-options="{enabled: false}" :rows="data.tryCalc.graph.slice(data.tryCalc.graph.length / 2, data.tryCalc.graph.length)" compactMode>
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field === 'number'">{{ props.row.lineNo }}</span>
                      <span v-if="props.column.field === 'plan'">{{ prettifyPrice(props.row.plan) }} руб.</span>
                    </template>
                    <div slot="emptystate">Данных нет</div>
                  </vue-good-table>
                </div>
                <!-- <div class="calc__info__download _col-1">
                  <a href="#" class="btn btn--black" style="" @click.prevent>Завести проект</a>
                </div> -->
              </div>
            </div>
            </template>
          </template>
        </ApolloQuery>
      </div>
    </div>
  </div>
</template>

<script>
import DownIcon from '@/components/svg/DownIcon'
import UPDATE_CALC from '@/graphql/mutations/UpdateCalc.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import CarIcon from '../../components/svg/CarIcon'
import TruckIcon from '../../components/svg/TruckIcon'
import TractorIcon from '../../components/svg/TractorIcon'
import FactoryIcon from '../../components/svg/FactoryIcon'
import RealEstateIcon from '../../components/svg/RealEstate'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'Calculator',
  components: { FactoryIcon, TractorIcon, TruckIcon, CarIcon, DownIcon, RealEstateIcon },
  beforeCreate () {
    if (this.$store.state.me.role === 'partner') this.$router.push({ path: '/' })
  },
  // created () {
  //   if (this.$store.state.me.role !== 'partner') {
  //     window.addEventListener('scroll', this.handleScroll)
  //   }
  // },
  // destroyed () {
  //   window.removeEventListener('scroll', this.handleScroll)
  // },
  beforeUpdate () {
    this.economy = Math.floor((+this.sum.replace(/\s/g, '').replace(',', '.')) / 3)
    if (!this.activeItem.realEstate) this.createFields.nds = null
    // else this.createFields.nds = 'НДС20'

    if (!this.activeItem.realEstate && !this.activeItem.residentialEstate) this.createFields.cadastral = null
  },
  data () {
    return {
      marginTopCalc: 15,
      indicators: {
        price: false,
        prepaid: false
      },
      isPartner: true,
      sum: 0,
      payment: 0,
      economy: 0,
      activeItem: {
        light: true,
        lightcommercial: false,
        commercial: false,
        special: false,
        equipment: false,
        realEstate: false,
        residentialEstate: false
      },
      defaultNameList: {
        light: 'Легковой автомобиль',
        lightcommercial: 'Легковой автомобиль',
        commercial: 'Коммерческий автомобиль',
        special: 'Специальная техника',
        equipment: 'Оборудование',
        realEstate: 'Коммерческая недвижимость',
        residentialEstate: 'Жилая недвижимость'
      },
      createFields: {
        name: 'Легковой автомобиль',
        price: 5000000,
        rate: 15,
        prepaid: 520000,
        duration: 20,
        managerCom: 0,
        agentCom: 0,
        strength: 0,
        stair: 1,
        graphType: 'annuity',
        propType: 'light',
        nds: null,
        cadastral: null
      },
      columns: [
        {
          label: '№',
          field: 'number'
        },
        {
          label: 'Сумма платежа',
          field: 'plan'
        }
      ],
      graphTypeSelect: [
        {
          value: 'annuity',
          name: 'Равномерный'
        }, {
          value: 'drawdown',
          name: 'Убывающий'
        }, {
          value: 'annuityStair',
          name: 'Равномерный | Лестница'
        }
      ],
      graphStairSelect: [
        {
          value: 1,
          name: 3
        },
        {
          value: 2,
          name: 6
        },
        {
          value: 3,
          name: 9
        },
        {
          value: 4,
          name: 12
        }
      ],
      propTypeSelect: [
        {
          value: 'light',
          name: 'Легковой автотранспорт'
        }, {
          value: 'commercial',
          name: 'Коммерческий автотранспорт'
        }, {
          value: 'special',
          name: 'Спец техника'
        }, {
          value: 'equipment',
          name: 'Оборудование'
        }, {
          value: 'realEstate',
          name: 'Недвижимость'
        }, {
          value: 'residentialEstate',
          name: 'Жилая недвижимость'
        }],
      additional: false
    }
  },
  methods: {
    // handleScroll (event) {
    //   if (window.innerWidth > 1130) {
    //     window.pageYOffset < 16
    //       ? this.marginTopCalc = 15
    //       : this.marginTopCalc = window.pageYOffset
    //   } else {
    //     this.marginTopCalc = 15
    //   }
    // },
    readSum (value) {
      this.sum = value
      return value
    },
    readPayment (value) {
      this.payment = value[1].plan
      return value
    },
    setType (event) {
      this.createFields.propType = event.target.value
      if (event.target.value === 'light') {
        this.activeItem.light = !(this.activeItem.realEstate = this.activeItem.commercial = this.activeItem.special = this.activeItem.equipment = this.activeItem.residentialEstate = false)
      }
      if (event.target.value === 'commercial') {
        this.activeItem.commercial = !(this.activeItem.realEstate = this.activeItem.light = this.activeItem.special = this.activeItem.equipment = this.activeItem.residentialEstate = false)
      }
      if (event.target.value === 'special') {
        this.activeItem.special = !(this.activeItem.realEstate = this.activeItem.light = this.activeItem.commercial = this.activeItem.equipment = this.activeItem.residentialEstate = false)
      }
      if (event.target.value === 'equipment') {
        this.activeItem.equipment = !(this.activeItem.realEstate = this.activeItem.light = this.activeItem.commercial = this.activeItem.special = this.activeItem.residentialEstate = false)
      }
      if (event.target.value === 'realEstate') {
        this.activeItem.realEstate = !(this.activeItem.light = this.activeItem.commercial = this.activeItem.special = this.activeItem.equipment = this.activeItem.residentialEstate = false)
      }
      if (event.target.value === 'residentialEstate') {
        this.activeItem.residentialEstate = !(this.activeItem.light = this.activeItem.commercial = this.activeItem.special = this.activeItem.equipment = this.activeItem.realEstate = false)
      }
      this.createFields.name = this.defaultNameList[event.target.value]
    },
    handleErrors (e) {
      // this.$notify({
      //   group: 'lsg-notify',
      //   text: e.message
      // })
    },
    prettifyPrice (price) {
      return Number(price).toLocaleString('ru', {
        maximumFractionDigits: 2
      })
    },
    async downloadCalc (calcId) {
      let currentInput = {
        id: calcId,
        leadId: '5e807e742f07c76dd7a473ad',
        ...this.createFields
      }
      if ((this.activeItem.realEstate || this.activeItem.residentialEstate) && !this.createFields.cadastral) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Заполните кадастровую стоимость'
        })
        return
      }
      await this.$apollo.mutate({
        mutation: UPDATE_CALC,
        variables: {
          input: currentInput
        },
        update: (store, { data }) => {
          if (data) {
            window.open(`${process.env.VUE_APP_HTTP}/download?type=calc&name=Calc&id=${calcId}`, '_blank')
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    }
  },
  computed: {
    modelPrice: {
      get () {
        return this.indicators.price ? this.createFields.price : this.createFields.price.toLocaleString()
      },
      set (value) {
        this.createFields.price = +value.replace(/\s/g, '')
        this.$emit('input', this.createFields.price)
      }
    },
    modelPrepaid: {
      get () {
        return this.indicators.prepaid ? this.createFields.prepaid : this.createFields.prepaid.toLocaleString()
      },
      set (value) {
        this.createFields.prepaid = +value.replace(/\s/g, '')
        this.$emit('input', this.createFields.prepaid)
      }
    },
    prepaidProc: {
      get () {
        return 100 * this.createFields.prepaid / this.createFields.price || 0
      },
      set (newValue) {
        this.createFields.prepaid = this.createFields.price * newValue / 100 || 0
      }
    },
    input () {
      if (
        this.createFields.duration === 0 ||
        this.createFields.price === 0 ||
        this.createFields.prepaid === 0) return null
      return {
        ...this.createFields
      }
    }
  }
}
</script>

<style lang="stylus">
.calc {
  grid()
  background $white
  border-radius 10px
  border 2px solid $pinky
  padding 32px 28px
  margin-top 12px
  align-items: flex-start;

  &--center {
    margin-top 0
    background $gray
    border none
    justify-content center
  }

  &__form {
    grid_item(50%)
    background: linear-gradient(0deg, $c2C3944 0%, $c2C3944 100%);
    padding 12px 16px 28px
    border-radius 10px
    +below(1130px) {
      grid_item(100%)
    }

    > ._row > .active {
      > i > svg {
        rect
        polygon {
          fill #ff8000
        }

        path:not([stroke]):not([fill]) {
          fill #ff8000
        }

        path[stroke] {
          stroke #ff8000
        }

        path[fill] {
          fill #ff8000
        }
      }
      > span {
        color #ff8000
      }
    }

    &__additional {
      display flex
      flex-direction column
      justify-content flex-start
      align-items flex-end

      &__header {
        display flex
        align-items center
        margin 16px 0 7px
        cursor pointer
        user-select none

        span {
          font-size 12px
          line-height 16px
          color $white
          margin-left 5px
        }

        i {
          width 16px
          height 16px
          display flex
          align-items center
          justify-content center

          svg {
            width 100%

            path[stroke] {
              stroke $white
            }

            path[fill] {
              fill $white
            }
          }
        }
      }

      &__body {

      }
    }

    &__item {
      display flex
      flex-wrap wrap

      label {
        width 100%
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $white;
        margin-bottom 5px
      }

      > input
      > ._row--width-fix input
      > textarea
      .mx-input-wrapper > input {
        background $c848A8F !important
        color $white !important
        width 100% !important

        &:focus {
          box-shadow none
        }
      }
      span {
        width 100%
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $white;
        margin-left 15px
      }

      > select {
        appearance none
        -moz-appearance none
        -webkit-appearance none
        margin 0
        width 100%
        color $white
        padding-right 40px
        display inline-flex
        align-items center
        justify-content center
        font-family font_regular
        background-color $c848A8F
        background-image: linear-gradient(45deg, transparent 50%, $white 50%), linear-gradient(135deg, $white 50%, transparent 50%);
        background-position calc(100% - 20px) calc(1em + 3px), calc(100% - 15px) calc(1em + 3px)
        background-size 5px 5px, 5px 5px
        background-repeat no-repeat
      }
    }

    &__note {
      margin 30px 0 0 0 !important
      color $white
      font-weight 400
      font-size 12px
      line-height 15px
    }

    &__type {
      cursor pointer
      display flex
      flex-direction column
      justify-content flex-start
      align-items center
      position relative

      &__list {
        margin-top: 0 !important;
      }

      input {
        visibility hidden
        opacity 0
        position absolute
      }

      i {
        width 50px
        height 50px
        display flex
        align-items center
        justify-content center

        svg {
          width 100%

          rect
          polygon {
            fill $white
          }

          path:not([stroke]):not([fill]) {
            fill $white
          }

          path[stroke] {
            stroke $white
          }

          path[fill] {
            fill $white
          }
        }
      }

      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $white;
        text-align center
        margin-top 12px
      }
    }
  }

  &__form--client {
    width 600px
    min-height 418px
    margin 0 !important
    flex-basis auto
    padding 12px 16px
    +below(1130px) {
      max-width 100%
      grid_item(100%)
      padding 12px 16px 28px
      min-height auto
    }
  }

  &__dark {
    padding 0 12px
    display flex
    flex-direction column
    width 100%

    &__price {
      font-size 20px !important
      line-height 26px !important
    }
  }

  &__results {
    background #dae3ea
    border-radius 15px
    padding 15px
    display flex
    flex-direction column
    align-items center
    justify-content center
    max-width 290px
    width 100%
    height 100%

    +below(1130px) {
      max-width 100%
      grid_item(100%)
    }

    &__white {
      display flex
      flex-direction column
      width 100%
      align-items center
      justify-content center
      text-align center
      background #fff
      border-radius 5px
      padding 15px 15px 21px
      color #000
    }

    &__title {
      font-weight 500
      font-size 16px
      line-height 20px
      text-align center
    }

    &__price {
      font-weight 400
      font-size 32px
      line-height 38px
      text-align center
      margin 10px 0
    }

    &__type {
      font-weight 400
      font-size 14px
      line-height 17px
      text-align center
    }

    &__dark {
      padding 0 12px
      display flex
      flex-direction column
      width 100%
    }

    &__item {
      display flex
      flex-direction column
      text-align center
      justify-content center
      padding 15px 15px 20px
      background #2a3742
      color #fff

      &:not(:last-child) {
        border-bottom 1px solid hsla(0,0%,100%,.1)
      }

      &:last-child {
        border-radius 0 0 5px 5px
        padding-bottom 40px
      }
    }

    &__btn {
      display flex
      justify-content center
      text-align center
      width 100%
      transform translateY(-50%)
    }

    &__note {
      font-weight 400
      font-size 12px
      line-height 15px
      text-align center
      color #000
      width 100%
    }
  }

  &__info {
    grid_item(50%)
    +below(1130px) {
      grid_item(100%)
    }

    &--center {
      margin 15px auto !important
      width 888px
      flex-basis auto

      +below(1130px) {
        width auto
        grid_item(100%)
      }
    }

    &__download {
      display flex
      justify-content flex-end
      margin 8px 0 12px
    }

    &__item {
      display flex
      flex-direction column
      align-items center
      justify-content center
      padding 12px 24px

      &--orange {
        background $orange

        span {
          font-weight bold
          font-style normal
        }
      }

      &__title {
        font-style: italic;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        text-align center
        color: $black;
      }

      &__subtitle {
        font-style: italic;
        font-weight: 300;
        font-size: 20px;
        line-height: 21px;
        color: #000000;
        margin-top 8px
        text-align center
      }
    }
  }
}

.calc-types {
  display grid !important
  grid-template-columns repeat(3, 1fr) !important

  +below(1330px) {
    grid-template-columns repeat(3, 1fr) !important
  }

  +below(1130px) {
    grid-template-columns repeat(6, 1fr) !important
  }

  +below(670px) {
    grid-template-columns repeat(3, 1fr) !important
  }

  +below(460px) {
    grid-template-columns repeat(2, 1fr) !important
  }

  &--client {
    display flex !important
    flex-flow row nowrap !important

    +below(670px) {
      display grid !important
      grid-template-columns repeat(3, 1fr) !important
    }

    +below(460px) {
      grid-template-columns repeat(2, 1fr) !important
    }
  }
}
</style>
