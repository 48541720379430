<template>
<svg xmlns="http://www.w3.org/2000/svg" class="" style="" viewBox="0 0 512 512" x="0" y="0" xmlns:xml="http://www.w3.org/XML/1998/namespace" xml:space="preserve" version="1.1"><g>
<g>
<g>
<path class="" fill="#ffffff" d="M 504.396 386.046 h -50.102 v -62.132 c 0 -4.199 -3.405 -7.604 -7.604 -7.604 s -7.604 3.405 -7.604 7.604 v 172.083 H 335.192 h -26.607 v -33.911 v -22.811 v -43.722 v -22.811 v -43.721 v -22.811 v -36.117 h 130.501 v 18.333 c 0 4.199 3.405 7.604 7.604 7.604 s 7.604 -3.405 7.604 -7.604 V 262.49 c 0 -4.199 -3.405 -7.604 -7.604 -7.604 H 342.796 V 8.4 c 0 -4.199 -3.405 -7.604 -7.604 -7.604 H 177.04 c -4.199 0 -7.604 3.405 -7.604 7.604 v 113.752 H 71.075 c -4.199 0 -7.604 3.405 -7.604 7.604 V 316.71 H 7.604 c -4.199 0 -7.604 3.405 -7.604 7.604 V 503.6 c 0 4.199 3.405 7.604 7.604 7.604 h 63.471 h 158.154 c 0.007 0 0.013 -0.001 0.019 -0.001 h 71.733 h 34.211 h 111.479 c 0.006 0 0.013 0.001 0.019 0.001 h 57.706 c 4.199 0 7.604 -3.405 7.604 -7.604 V 393.65 C 512 389.451 508.595 386.046 504.396 386.046 Z M 63.471 495.996 H 15.208 V 331.918 h 48.263 V 495.996 Z M 221.625 495.996 H 177.04 v 0.001 H 78.679 v -42.049 h 142.946 V 495.996 Z M 221.625 387.542 h -68.531 c -4.199 0 -7.604 3.405 -7.604 7.604 s 3.405 7.604 7.604 7.604 h 68.531 v 35.99 H 78.679 v -35.99 h 38.929 c 4.199 0 7.604 -3.405 7.604 -7.604 c 0 -4.199 -3.405 -7.604 -7.604 -7.604 H 78.679 v -35.99 h 142.946 V 387.542 Z M 221.625 336.346 H 78.679 v -12.032 v -23.959 h 142.946 V 336.346 Z M 221.625 285.148 H 78.679 v -35.989 h 142.946 V 285.148 Z M 221.625 233.951 H 78.679 v -35.99 h 142.946 V 233.951 Z M 221.625 182.753 H 78.679 v -45.394 h 142.946 V 182.753 Z M 293.377 262.491 v 43.721 v 22.811 v 43.721 v 22.811 v 43.722 v 22.811 v 33.911 h -56.544 V 129.755 c 0 -4.199 -3.404 -7.604 -7.604 -7.604 h -10.367 v -15.207 c 0 -4.199 -3.404 -7.604 -7.604 -7.604 s -7.604 3.405 -7.604 7.604 v 15.207 h -19.01 V 16.004 h 142.943 v 238.883 h -26.607 C 296.781 254.887 293.377 258.291 293.377 262.491 Z M 496.792 495.996 h -42.497 v -94.743 h 42.497 V 495.996 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 300.981 165.543 c -4.2 0 -7.604 3.405 -7.604 7.604 v 22.811 c 0 4.2 3.405 7.604 7.604 7.604 c 4.2 0 7.604 -3.405 7.604 -7.604 v -22.811 C 308.585 168.948 305.181 165.543 300.981 165.543 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 256.12 165.543 c -4.2 0 -7.604 3.405 -7.604 7.604 v 22.811 c 0 4.2 3.405 7.604 7.604 7.604 c 4.2 0 7.604 -3.405 7.604 -7.604 v -22.811 C 263.724 168.948 260.32 165.543 256.12 165.543 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 300.981 99.34 c -4.2 0 -7.604 3.405 -7.604 7.604 v 22.811 c 0 4.199 3.405 7.604 7.604 7.604 c 4.2 0 7.604 -3.405 7.604 -7.604 v -22.811 C 308.585 102.745 305.181 99.34 300.981 99.34 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 256.12 99.34 c -4.2 0 -7.604 3.405 -7.604 7.604 v 22.811 c 0 4.199 3.405 7.604 7.604 7.604 c 4.2 0 7.604 -3.405 7.604 -7.604 v -22.811 C 263.724 102.745 260.32 99.34 256.12 99.34 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 211.258 33.137 c -4.2 0 -7.604 3.405 -7.604 7.604 v 22.811 c 0 4.199 3.405 7.604 7.604 7.604 c 4.2 0 7.604 -3.405 7.604 -7.604 V 40.741 C 218.862 36.542 215.459 33.137 211.258 33.137 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 300.981 33.137 c -4.2 0 -7.604 3.405 -7.604 7.604 v 22.811 c 0 4.199 3.405 7.604 7.604 7.604 c 4.2 0 7.604 -3.405 7.604 -7.604 V 40.741 C 308.585 36.542 305.181 33.137 300.981 33.137 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 256.12 33.137 c -4.2 0 -7.604 3.405 -7.604 7.604 v 22.811 c 0 4.199 3.405 7.604 7.604 7.604 c 4.2 0 7.604 -3.405 7.604 -7.604 V 40.741 C 263.724 36.542 260.32 33.137 256.12 33.137 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 296.918 233.26 c -3.077 1.936 -4.372 5.964 -2.957 9.326 c 1.215 2.868 4.073 4.766 7.202 4.689 c 3.188 -0.078 6.025 -2.187 7.034 -5.211 C 310.414 235.413 302.808 229.554 296.918 233.26 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 256.12 232.075 c -4.2 0 -7.604 3.405 -7.604 7.604 v 22.811 c 0 4.2 3.405 7.604 7.604 7.604 c 4.2 0 7.604 -3.405 7.604 -7.604 v -22.811 C 263.724 235.479 260.32 232.075 256.12 232.075 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 256.12 431.671 c -4.2 0 -7.604 3.405 -7.604 7.604 v 22.811 c 0 4.199 3.405 7.604 7.604 7.604 c 4.2 0 7.604 -3.405 7.604 -7.604 v -22.811 C 263.724 435.075 260.32 431.671 256.12 431.671 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 256.12 365.139 c -4.2 0 -7.604 3.405 -7.604 7.604 v 22.811 c 0 4.199 3.405 7.604 7.604 7.604 c 4.2 0 7.604 -3.405 7.604 -7.604 v -22.811 C 263.724 368.544 260.32 365.139 256.12 365.139 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 256.12 298.608 c -4.2 0 -7.604 3.405 -7.604 7.604 v 22.811 c 0 4.199 3.405 7.604 7.604 7.604 c 4.2 0 7.604 -3.405 7.604 -7.604 v -22.811 C 263.724 302.012 260.32 298.608 256.12 298.608 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 415.897 429.355 h -84.123 c -4.2 0 -7.604 3.405 -7.604 7.604 s 3.404 7.604 7.604 7.604 h 84.123 c 4.2 0 7.604 -3.405 7.604 -7.604 S 420.098 429.355 415.897 429.355 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 415.897 465.299 h -84.123 c -4.2 0 -7.604 3.405 -7.604 7.604 c 0 4.199 3.404 7.604 7.604 7.604 h 84.123 c 4.2 0 7.604 -3.405 7.604 -7.604 C 423.501 468.703 420.098 465.299 415.897 465.299 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 415.897 393.413 h -84.123 c -4.2 0 -7.604 3.405 -7.604 7.604 s 3.404 7.604 7.604 7.604 h 84.123 c 4.2 0 7.604 -3.405 7.604 -7.604 S 420.098 393.413 415.897 393.413 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 415.897 285.583 h -84.123 c -4.2 0 -7.604 3.405 -7.604 7.604 c 0 4.199 3.404 7.604 7.604 7.604 h 84.123 c 4.2 0 7.604 -3.405 7.604 -7.604 C 423.501 288.988 420.098 285.583 415.897 285.583 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 415.897 357.469 h -84.123 c -4.2 0 -7.604 3.405 -7.604 7.604 s 3.404 7.604 7.604 7.604 h 84.123 c 4.2 0 7.604 -3.405 7.604 -7.604 S 420.098 357.469 415.897 357.469 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 415.897 321.527 h -84.123 c -4.2 0 -7.604 3.405 -7.604 7.604 s 3.404 7.604 7.604 7.604 h 84.123 c 4.2 0 7.604 -3.405 7.604 -7.604 S 420.098 321.527 415.897 321.527 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 39.339 344.004 c -4.2 0 -7.604 3.405 -7.604 7.604 v 19.769 c 0 4.199 3.404 7.604 7.604 7.604 c 4.2 0 7.604 -3.405 7.604 -7.604 v -19.769 C 46.943 347.409 43.538 344.004 39.339 344.004 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 39.339 396.469 c -4.2 0 -7.604 3.405 -7.604 7.604 v 19.769 c 0 4.199 3.404 7.604 7.604 7.604 c 4.2 0 7.604 -3.405 7.604 -7.604 v -19.769 C 46.943 399.873 43.538 396.469 39.339 396.469 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 39.339 448.934 c -4.2 0 -7.604 3.405 -7.604 7.604 v 19.769 c 0 4.199 3.404 7.604 7.604 7.604 c 4.2 0 7.604 -3.405 7.604 -7.604 v -19.769 C 46.943 452.338 43.538 448.934 39.339 448.934 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 475.544 419.501 c -4.2 0 -7.604 3.405 -7.604 7.604 v 21.52 c 0 4.199 3.404 7.604 7.604 7.604 s 7.604 -3.405 7.604 -7.604 v -21.52 C 483.148 422.906 479.744 419.501 475.544 419.501 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 453.251 147.293 h -92.763 c -4.2 0 -7.604 3.405 -7.604 7.604 s 3.404 7.604 7.604 7.604 h 92.763 c 4.2 0 7.604 -3.405 7.604 -7.604 S 457.451 147.293 453.251 147.293 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 482.145 179.228 h -66.912 c -4.2 0 -7.604 3.405 -7.604 7.604 s 3.404 7.604 7.604 7.604 h 66.912 c 4.2 0 7.604 -3.405 7.604 -7.604 S 486.345 179.228 482.145 179.228 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 136.941 57.571 H 70.029 c -4.2 0 -7.604 3.405 -7.604 7.604 s 3.405 7.604 7.604 7.604 h 66.912 c 4.2 0 7.604 -3.405 7.604 -7.604 S 141.142 57.571 136.941 57.571 Z" data-original="#000000"></path>
</g>
</g>
<g>
<g>
<path class="" fill="#ffffff" d="M 98.923 82.916 H 32.012 c -4.2 0 -7.604 3.405 -7.604 7.604 s 3.405 7.604 7.604 7.604 h 66.912 c 4.2 0 7.604 -3.405 7.604 -7.604 S 103.124 82.916 98.923 82.916 Z" data-original="#000000"></path>
</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</g>
</svg>
</template>

<script>
export default {
  name: 'RealEstate'
}
</script>
